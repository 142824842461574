import {attr, controller, target, targets} from '@github/catalyst'
import {get, parseRequestOptionsFromJSON, supported} from '@github/webauthn-json/browser-ponyfill'
import type {CredentialRequestOptionsJSON} from '@github/webauthn-json/browser-ponyfill'
import {requestSubmit} from '@github-ui/form-utils'

export enum State {
  Initializing = 'initializing',
  Unsupported = 'unsupported',
  Ready = 'ready',
  Waiting = 'waiting',
  Error = 'error',
  Submitting = 'submitting',
}

@controller
export class WebauthnGetElement extends HTMLElement {
  state: State = State.Initializing
  @target button: HTMLButtonElement
  @target buttonText: HTMLElement
  // `messages` contains all the message elements.
  @targets messages: HTMLElement[]
  @target capitalizedDescription: HTMLElement
  @target unsupportedMessage: HTMLElement
  @target waitingMessage: HTMLElement
  @target errorMessage: HTMLElement
  @target errorText: HTMLElement

  static attrPrefix = ''
  @attr dataJson = ''
  private originalButtonText: string
  private hasErrored = false
  private originalErrorText: string | null

  connectedCallback(): void {
    this.originalButtonText = this.getCurrentButtonText()
    this.originalErrorText = this.errorText.textContent
    this.setState(supported() ? State.Ready : State.Unsupported)
  }

  getCurrentButtonText(): string {
    return this.buttonText.textContent || ''
  }

  setCurrentButtonText(text: string): void {
    this.buttonText.textContent = text
  }

  setState(state: State): void {
    // Reset to defaults
    const retryMessage = this.button.getAttribute('data-retry-message') || this.originalButtonText
    const buttonText = this.hasErrored ? retryMessage : this.originalButtonText
    this.setCurrentButtonText(buttonText)
    this.button.disabled = false
    this.button.hidden = false
    this.errorText.textContent = ''
    for (const elem of this.messages) {
      elem.hidden = true
    }

    switch (state) {
      case State.Initializing:
        this.button.disabled = true
        break
      case State.Unsupported:
        this.button.disabled = true
        this.unsupportedMessage.hidden = false
        break
      case State.Ready:
        break
      case State.Waiting:
        this.waitingMessage.hidden = false
        this.button.hidden = true
        break
      case State.Error:
        this.errorMessage.hidden = false
        this.errorText.textContent = this.originalErrorText
        break
      case State.Submitting:
        this.setCurrentButtonText('Verifying…')
        this.button.disabled = true
        break
      default:
        throw new Error('invalid state')
    }

    this.state = state
  }

  // silent_unless_success: don't show waiting or error status. This is for automatically attempting a prompt at
  // page/modal load time, without showing confusing UI if the browser rejects the attempt due to a missing user
  // gesture. Most browsers allow at least one such prompt per page load, but we can't rely on it. In theory we could
  // try to show an error to the user depending on the `get` rejection, but the spec is still in flux and browsers
  // constantly change their mind (and have bugs). So we err on the side of not showing an error.
  async prompt(event?: Event, silent_unless_success?: boolean): Promise<void> {
    event?.preventDefault() // prevent default page form submission
    this.dispatchEvent(new CustomEvent('webauthn-get-prompt'))
    try {
      if (!silent_unless_success) {
        this.setState(State.Waiting)
      }

      const json: CredentialRequestOptionsJSON = JSON.parse(this.dataJson)
      const signResponse = await get(parseRequestOptionsFromJSON(json))
      this.setState(State.Submitting)

      // Each `<webauthn-get>` element is currently embedded in a form (not
      // the other way around), so we have to query for for the form outside of it.
      const form = this.closest<HTMLFormElement>('.js-webauthn-form')!
      const responseField = form.querySelector<HTMLInputElement>('.js-webauthn-response')!
      responseField.value = JSON.stringify(signResponse)
      requestSubmit(form)
    } catch (error) {
      if (!silent_unless_success) {
        this.hasErrored = true
        this.setState(State.Error)
        throw error
      }
    }
  }
}
