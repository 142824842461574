import {ghTrustedTypes} from '@github-ui/trusted-types'

export class SourceRelNotFoundError extends Error {}
export const workerSrcRelPolicy = ghTrustedTypes.createPolicy('worker-src-rel', {
  createScriptURL: (rel: string) => {
    if (!rel.match(/[A-Za-z0-9-]+/)) {
      throw new Error('Invalid rel')
    }
    const href = document.head.querySelector<HTMLLinkElement>(`link[rel=${rel}]`)?.href
    if (!href) {
      throw new SourceRelNotFoundError('No href found for rel')
    }
    return href
  },
})
