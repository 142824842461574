import {onCLS, onFCP, onFID, onLCP, onTTFB, onINP} from 'web-vitals'
import type {Metric} from 'web-vitals'
import {loaded} from '@github-ui/document-ready'
import {wasServerRendered} from '@github-ui/ssr-utils'
import {sendStats} from '@github-ui/stats'
import {HPCTimingEvent} from './hpc'
import {MECHANISM_MAPPING, getCurrentReactAppName} from '@github-ui/soft-nav'
import {sendStatToHydro} from './hydro-stats'

type MetricOrHPC = Metric | HPCTimingEvent
const initialRenderIsSSR = wasServerRendered()

export function sendVitals(metric: MetricOrHPC) {
  const {name, value} = metric
  const stat: PlatformBrowserPerformanceWebVitalTiming = {
    name: window.location.href,
    app: getCurrentReactAppName() || 'rails',
  }
  stat[name.toLowerCase() as Lowercase<typeof name>] = value

  if (name === 'HPC') {
    stat.soft = metric.soft
    stat.ssr = metric.ssr
    stat.mechanism = MECHANISM_MAPPING[metric.mechanism]
  } else {
    stat.ssr = initialRenderIsSSR
  }

  const syntheticTest = document.querySelector('meta[name="synthetic-test"]')
  if (syntheticTest) {
    stat.synthetic = true
  }

  sendStats({webVitalTimings: [stat]})

  sendStatToHydro(stat)

  updateStaffBar(name, value)
}

function updateStaffBar(name: string, value: number) {
  const staffBarContainer = document.querySelector('#staff-bar-web-vitals')
  const metricContainer = staffBarContainer?.querySelector(`[data-metric=${name.toLowerCase()}]`)

  if (!metricContainer) {
    return
  }

  metricContainer.textContent = value.toPrecision(6)
}

function isTimingSuppported(): boolean {
  return !!(window.performance && window.performance.timing && window.performance.getEntriesByType)
}

async function sendTimingResults() {
  if (!isTimingSuppported()) return

  await loaded
  await new Promise(resolve => setTimeout(resolve))

  sendResourceTimings()
  sendNavigationTimings()
}

const sendResourceTimings = () => {
  const resourceTimings = window.performance
    .getEntriesByType('resource')

    .map(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (timing: any): PlatformBrowserPerformanceNavigationTiming => ({
        name: timing.name,
        entryType: timing.entryType,
        startTime: timing.startTime,
        duration: timing.duration,
        initiatorType: timing.initiatorType,
        nextHopProtocol: timing.nextHopProtocol,
        workerStart: timing.workerStart,

        workerTiming: (timing.workerTiming || []).map(
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (workerTiming: any): PlatformBrowserPerformanceWorkerTiming => ({
            duration: workerTiming.duration,
            startTime: workerTiming.startTime,
            name: workerTiming.name,
            entryType: workerTiming.entryType,
          }),
        ),

        serverTiming: (timing.serverTiming || []).map(
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (serverTiming: any): PlatformBrowserPerformanceServerTiming => ({
            duration: serverTiming.duration,
            description: serverTiming.description,
            name: serverTiming.name,
          }),
        ),
        redirectStart: timing.redirectStart,
        redirectEnd: timing.redirectEnd,
        fetchStart: timing.fetchStart,
        domainLookupStart: timing.domainLookupStart,
        domainLookupEnd: timing.domainLookupEnd,
        connectStart: timing.connectStart,
        connectEnd: timing.connectEnd,
        secureConnectionStart: timing.secureConnectionStart,
        requestStart: timing.requestStart,
        responseStart: timing.responseStart,
        responseEnd: timing.responseEnd,
        transferSize: timing.transferSize,
        encodedBodySize: timing.encodedBodySize,
        decodedBodySize: timing.decodedBodySize,
      }),
    )

  if (resourceTimings.length) {
    sendStats({resourceTimings})
  }
}

const sendNavigationTimings = () => {
  const navigationTimings = window.performance
    .getEntriesByType('navigation')

    .map(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (timing: any): PlatformBrowserPerformanceNavigationTiming => ({
        activationStart: timing.activationStart,
        name: timing.name,
        entryType: timing.entryType,
        startTime: timing.startTime,
        duration: timing.duration,
        initiatorType: timing.initiatorType,
        nextHopProtocol: timing.nextHopProtocol,
        workerStart: timing.workerStart,
        workerTiming: (timing.workerTiming || []).map(
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (workerTiming: any): PlatformBrowserPerformanceWorkerTiming => ({
            duration: workerTiming.duration,
            startTime: workerTiming.startTime,
            name: workerTiming.name,
            entryType: workerTiming.entryType,
          }),
        ),

        serverTiming: (timing.serverTiming || []).map(
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (serverTiming: any): PlatformBrowserPerformanceServerTiming => ({
            duration: serverTiming.duration,
            description: serverTiming.description,
            name: serverTiming.name,
          }),
        ),
        redirectStart: timing.redirectStart,
        redirectEnd: timing.redirectEnd,
        fetchStart: timing.fetchStart,
        domainLookupStart: timing.domainLookupStart,
        domainLookupEnd: timing.domainLookupEnd,
        connectStart: timing.connectStart,
        connectEnd: timing.connectEnd,
        secureConnectionStart: timing.secureConnectionStart,
        requestStart: timing.requestStart,
        responseStart: timing.responseStart,
        responseEnd: timing.responseEnd,
        transferSize: timing.transferSize,
        encodedBodySize: timing.encodedBodySize,
        decodedBodySize: timing.decodedBodySize,
        unloadEventStart: timing.unloadEventStart,
        unloadEventEnd: timing.unloadEventEnd,
        domInteractive: timing.domInteractive,
        domContentLoadedEventStart: timing.domContentLoadedEventStart,
        domContentLoadedEventEnd: timing.domContentLoadedEventEnd,
        domComplete: timing.domComplete,
        loadEventStart: timing.loadEventStart,
        loadEventEnd: timing.loadEventEnd,
        type: timing.type,
        redirectCount: timing.redirectCount,
      }),
    )

  if (navigationTimings.length) {
    sendStats({navigationTimings})
  }
}

sendTimingResults()
onCLS(sendVitals)
onFCP(sendVitals)
onFID(sendVitals)
onLCP(sendVitals)
onTTFB(sendVitals)
onINP(sendVitals)
