// Report uncaught JS errors to Sentry
//   https://sentry.io/github/github-js

import {getOrCreateClientId} from '@github/hydro-analytics-client'
import {isSupported} from '@github/browser-support'
import {parse} from 'stacktrace-parser'
import {requestUri} from '@github-ui/analytics-overrides'
import {getSoftNavReferrer} from '@github-ui/soft-nav'
import {bundler} from '@github-ui/runtime-environment'
import {ssrSafeWindow} from '@github-ui/ssr-utils'

let extensionErrors = false
let errorsReported = 0
const loadTime = Date.now()

type ErrorContext = {
  message?: string
}

function isExpectedError(error: Error): boolean {
  // We use AbortController to control events and some workflows. When we call `abort()` on it, it will raise an
  // `AbortError` which doesn't represent a real error, so we don't want to report it.
  if (error.name === 'AbortError') return true
  // Failed to fetch errors are usually related to the user's network connection. They also do not represent
  // real errors related to our code, so we will also ignore them.
  if (error.name === 'TypeError' && error.message === 'Failed to fetch') return true

  return false
}

// @deprecated Re-throw the caught exception instead.
export function reportError(error: Error, context: ErrorContext = {}) {
  if (!isExpectedError(error)) {
    report(errorContext(formatError(error), context))
  }
}

// Report context info to Sentry.
async function report(context: PlatformReportBrowserErrorInput) {
  if (!reportable()) return

  const url = document.head?.querySelector<HTMLMetaElement>('meta[name="browser-errors-url"]')?.content
  if (!url) return

  if (isExtensionError(context.error.stacktrace)) {
    extensionErrors = true
    return
  }

  errorsReported++

  try {
    await fetch(url, {method: 'post', body: JSON.stringify(context)})
  } catch {
    // Error reporting failed so do nothing.
  }
}

function formatError(error: Error): PlatformJavascriptError {
  return {type: error.name, value: error.message, stacktrace: stacktrace(error)}
}

function errorContext(error: PlatformJavascriptError, context: ErrorContext = {}): PlatformReportBrowserErrorInput {
  return Object.assign(
    {
      error,
      sanitizedUrl: requestUri() || window.location.href,
      readyState: document.readyState,
      referrer: getSoftNavReferrer(),
      timeSinceLoad: Math.round(Date.now() - loadTime),
      user: pageUser() || undefined,
      bundler,
      ui: Boolean(document.querySelector('meta[name="ui"]')),
    },
    context,
  )
}

export function stacktrace(error: Error): PlatformStackframe[] {
  return parse(error.stack || '').map(frame => ({
    filename: frame.file || '',
    function: String(frame.methodName),
    lineno: (frame.lineNumber || 0).toString(),
    colno: (frame.column || 0).toString(),
  }))
}

const extensions = /(chrome|moz|safari)-extension:\/\//

// Does this stack trace contain frames from browser extensions?
function isExtensionError(stack: PlatformStackframe[]): boolean {
  return stack.some(frame => extensions.test(frame.filename) || extensions.test(frame.function))
}

export function pageUser() {
  const login = document.head?.querySelector<HTMLMetaElement>('meta[name="user-login"]')?.content
  if (login) return login

  const clientId = getOrCreateClientId()
  return `anonymous-${clientId}`
}

let unloaded = false
ssrSafeWindow?.addEventListener('pageshow', () => (unloaded = false))
ssrSafeWindow?.addEventListener('pagehide', () => (unloaded = true))

function reportable() {
  return !unloaded && !extensionErrors && errorsReported < 10 && isSupported()
}

if (typeof BroadcastChannel === 'function') {
  const sharedWorkerErrorChannel = new BroadcastChannel('shared-worker-error')
  sharedWorkerErrorChannel.addEventListener('message', event => {
    // SharedWorker will emit a formatted error
    reportError(event.data.error)
  })
}
